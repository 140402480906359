export const MuiButton = palette => ({
  defaultProps: {
    variant: 'contained'
  },
  styleOverrides: {
    contained: {
      '&.Mui-disabled': {
        backgroundColor: palette.button.primary.disabled,
        color: palette.button.primary.textDisabled
      },
      '&:focused': {
        backgroundColor: palette.button.primary.focused
      },
      '&:hover': {
        backgroundColor: palette.button.primary.hover,
        boxShadow: 'none'
      },
      backgroundColor: palette.button.primary.background,
      borderRadius: 100,
      boxShadow: 'none',
      color: palette.button.primary.onSurface
    },
    endIcon: {
      '& > span': {
        color: 'inherit'
      },
      color: 'inherit'
    },
    outlined: {
      '&.Mui-disabled': {
        borderColor: palette.button.outlined.disabled,
        color: palette.button.outlined.textDisabled
      },
      ':focused': {
        backgroundColor: palette.button.outlined.focused,
        borderColor: palette.button.outlined.background
      },
      ':hover': {
        backgroundColor: palette.button.outlined.hover,
        borderColor: palette.button.outlined.background
      },
      border: '1px solid',
      borderRadius: 100,
      color: palette.button.outlined.onSurface
    },
    root: {
      '&:focus-visible': {
        outline: '2px solid',
        outlineColor: palette.secondary.tonal
      },
      borderRadius: 4,
      color: palette.surface.onSurface,
      fontFamily: 'aktiv-grotesk, sans-serif',
      fontSize: '0.875rem',
      fontWeight: 500,
      letterSpacing: 0.5,
      lineHeight: 1.4,
      maxHeight: '2.5rem',
      padding: '1rem 1.5rem',
      textDecoration: 'none',
      textTransform: 'capitalize',
      whiteSpace: 'nowrap',
      width: 'fit-content'
    },
    startIcon: {
      '& > span': {
        color: 'inherit'
      },
      color: 'inherit'
    },
    text: {
      '&.Mui-disabled': {
        backgroundColor: 'transparent',
        color: palette.button.text.disabled
      },
      '&:focused': {
        backgroundColor: palette.button.text.focused
      },
      '&:hover': {
        backgroundColor: palette.button.text.hover
      },
      borderRadius: 100,
      color: palette.button.text.onSurface,
      textDecoration: 'none'
    }
  },
  variants: [
    {
      props: { fullWidth: true },
      style: {
        width: '100%'
      }
    },
    {
      props: { variant: 'tonal' },
      style: {
        '&.Mui-disabled': {
          backgroundColor: palette.button.tonal.disabled,
          color: palette.button.tonal.textDisabled
        },
        '&:focused': {
          backgroundColor: palette.button.tonal.focused
        },
        '&:hover': {
          backgroundColor: palette.button.tonal.hover
        },
        backgroundColor: palette.button.tonal.background,
        borderRadius: 100,
        color: palette.button.tonal.onSurface
      }
    },
    {
      props: { variant: 'poll' },
      style: {
        ':hover': {
          backgroundColor: palette.surface.high
        },
        backgroundColor: palette.surface.container,
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
        fontWeight: 400,
        maxHeight: 'unset',
        padding: '8px 16px',
        textTransform: 'none',
        whiteSpace: 'unset',
        width: '100%'
      }
    },
    {
      props: { selected: true, variant: 'poll' },
      style: {
        backgroundColor: palette.surface.highest,
        fontWeight: 500
      }
    },
    {
      props: { variant: 'select' },
      style: {
        ':hover': {
          backgroundColor: palette.surface.high
        },
        backgroundColor: palette.surface.container,
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
        fontWeight: 400,
        textTransform: 'none'
      }
    },
    {
      props: { selected: true, variant: 'select' },
      style: {
        backgroundColor: palette.primary.container,
        fontWeight: 500
      }
    },
    {
      props: { variant: 'sidebar' },
      style: {
        ':hover': {
          backgroundColor: palette.surface.high
        },
        borderRadius: 100,
        color: palette.surface.onSurface,
        fontWeight: 400,
        justifyContent: 'flex-start',
        whiteSpace: 'unset',
        width: '100%'
      }
    },
    {
      props: { selected: true, variant: 'sidebar' },
      style: {
        backgroundColor: palette.surface.high,
        borderRadius: 100,
        color: palette.surface.onSurface
      }
    },
    {
      props: { error: true, variant: 'text' },
      style: {
        ':hover': {
          backgroundColor: palette.error.hover,
          color: palette.error.onSurface
        },
        color: palette.error.main
      }
    },
    // We have two of these because the dom doesn't like error as a bool but unsure what else is using the above version so leaving it for now
    {
      props: { error: 'true', variant: 'text' },
      style: {
        ':hover': {
          backgroundColor: palette.error.hover,
          color: palette.error.onSurface
        },
        color: palette.error.main
      }
    },
    {
      props: { error: true, variant: 'contained' },
      style: {
        ':hover': {
          backgroundColor: palette.error.hover
        },
        backgroundColor: palette.error.main,
        color: palette.error.onSurface
      }
    },
    {
      props: { variant: 'stats-bar' },
      style: {
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
          color: palette.button.text.disabled
        },
        '&:focused': {
          backgroundColor: palette.button.text.focused
        },
        '&:hover': {
          backgroundColor: palette.button.text.hover
        },
        borderRadius: 8,
        color: palette.surface.onSurface,
        padding: 12,
        textDecoration: 'none'
      }
    },
    {
      props: { selected: true, variant: 'stats-bar' },
      style: {
        backgroundColor: palette.primary.container
      }
    }
  ]
})
