import { useMemo, useRef, useState } from 'react'
import { compose, isNil, nthArg, path, prop, propEq } from 'ramda'
import { useGetFeaturedContentQuery } from 'api/schoolApi'
import { useGetCardsQuery } from 'api/cardsApi'
import { Box, Divider, Pagination, Stack, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import CardHandler from 'components/cards/CardHandler'
import CardGridWrapper from 'components/cards/CardGridWrapper'

const Group = () => {
  const { groupId } = useParams()
  const { domain } = useSelector(prop('school'))
  const [page, setPage] = useState(1)
  const scrollRef = useRef(null)

  const { data: featuredContent } = useGetFeaturedContentQuery()

  // need to parse int since groupId is a string
  const group = useMemo(() => {
    if (isNil(featuredContent)) return []

    return prop('groups', featuredContent).find(propEq(parseInt(groupId), 'id'))
  }, [featuredContent, groupId])

  const cardParams = {
    'filter[content_groups]': groupId,
    org_domain: domain,
    page,
    perPage: 15
  }

  const { data } = useGetCardsQuery(cardParams, {
    skip: isNil(featuredContent)
  })

  const cards = useMemo(() => {
    if (isNil(data)) return []
    return prop('data', data)
  }, [data])

  const handleNav = () => window.scrollTo(0, scrollRef.current.offsetTop)

  return (
    <Stack spacing={4.5} sx={{ mt: -4, pb: { mobile: 4, laptop: 8 } }}>
      {prop('hero_image_url', group) && (
        <Box
          sx={{
            background: `url(${prop('hero_image_url', group)})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: 383,
            width: '100%'
          }}
          width="100%"
        />
      )}
      <Stack
        spacing={4.5}
        sx={{
          alignSelf: 'center',
          maxWidth: 1008,
          px: { mobile: 2, tablet: 3 }
        }}
      >
        <Stack
          alignItems="center"
          // pb={4} px={2}
          ref={scrollRef}
          spacing={3}
        >
          <Typography sx={{ alignSelf: 'flex-start' }} variant="h1">
            {prop('display_text', group)}
          </Typography>
          <Divider sx={{ m: 0, mb: 3 }} />
          <CardGridWrapper>
            {cards.map((card, index) => (
              <CardHandler card={card} key={index} />
            ))}
          </CardGridWrapper>
          <Pagination
            count={path(['meta', 'lastPage'], data)}
            onChange={compose(handleNav, setPage, nthArg(1))}
            page={page}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Group
